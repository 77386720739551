<template>
  <dashboard-content full-width>
    <v-row>
      <v-col class="col-12 py-0">
        <h1 class="mb-4">
          Subscriptions
        </h1>

        <v-data-table
          item-key="id"
          :headers="headers"
          :items="subscribers"
          :loading="loading"
          class="elevation-1"
        />
      </v-col>
    </v-row>
  </dashboard-content>
</template>

<script>
import DashboardContent from '@/components/dashboard/DashboardContent'
import { mapActions } from 'vuex'

export default {
  components: { DashboardContent },
  data() {
    return {
      subscribers: [],
      loading: true,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Status', value: 'status' },
      ],
    }
  },
  async created() {
    const subscriptions = await this.getSubscriptions()
    let subscribers = []
    subscriptions.forEach(sub => {
      if (sub?.first_name && sub?.last_name && sub?.email && sub?.subscription) {
        const name = `${sub.first_name} ${sub.last_name}`
        subscribers.push({
          name: name,
          email: sub.email,
          status: sub.subscription.status
        })
      }
    })
    
    this.subscribers = subscribers
    this.loading = false
  },
  methods: {
    ...mapActions('authentication', ['getSubscriptions'])
  }
}
</script>
